let variant;

// This comes from DefinePlugin in webpack.config.js
declare var ENUMS_BUILD_VARIANT: string;

// FIXME: Make it stop complaining about ENUMS_BUILD_VARIANT
if (ENUMS_BUILD_VARIANT == 'development' || !ENUMS_BUILD_VARIANT) {
  variant = 'current';
} else {
  variant = ENUMS_BUILD_VARIANT;
}

const TraitsJSON = await import(`../traits.${variant}.json`).then((module) => module.default);
import { organizeTraitsByCategory } from './helpers';
import { TraitsWrapper, Traits } from './wrappers';

export const traits = new TraitsWrapper(TraitsJSON as unknown as Traits);
export const traitsByCategory = organizeTraitsByCategory(traits);
