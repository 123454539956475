/**
 * Wraps the animal traits dictionary in a wrapper object. The traits dictionary, by default,
 * has a leaky abstraction due to performance reasons. This getter wrapper is an elegant work-around for it,
 * which handles things like one trait category inheriting traits from another trait category and
 * derived trait data lists (lists generated on the fly from existing data).
 * It is _very_ important that the overall shape of the wrapper conforms to the original shape of the traits dictionary,
 * otherwise consumers down the line might break.
 */
type TraitValue = {
  has_traits: boolean;
  trait_category: number;
  traits_data: string[];
  traits_list: Record<string, string>;
  traits_with_aliases_data: string[];
  traits_with_aliases_list: Record<string, string>;
};
export type Traits = Record<string, TraitValue>;

export class TraitsWrapper {
  constructor(traits: Traits) {
    const keys = Object.keys(traits);

    keys.map((key) =>
      Object.defineProperty(this, key, {
        enumerable: true,
        get() {
          const parent = traits[key].trait_category;
          const has_traits = traits[key].has_traits || traits[parent]?.has_traits;
          const trait_category = parent ? parseInt(key, 10) : parent;
          const traits_list = traits[key].traits_list || traits[parent].traits_list;
          const traits_with_aliases_list =
            traits[key].traits_with_aliases_list || traits[parent].traits_with_aliases_list;

          const underived = {
            has_traits,
            trait_category,
            // Important: due to the way JS works, an object without a property and an object with
            // a property set to undefined are not the same, hence the additional checks
            ...(!!traits_list && { traits_list }),
            ...(!!traits_with_aliases_list && { traits_with_aliases_list }),
          };

          // traits_data & traits_with_aliases_data are basically lists of trait names
          // derived from the values of traits_list and traits_with_aliases_list, respectively
          const withDerived = !!traits_list
            ? Object.defineProperty(underived, 'traits_data', {
                enumerable: true,
                get() {
                  return Object.values(underived.traits_list);
                },
              })
            : underived;

          const complete = !!traits_with_aliases_list
            ? Object.defineProperty(withDerived, 'traits_with_aliases_data', {
                enumerable: true,
                get() {
                  return Object.values(underived.traits_with_aliases_list);
                },
              })
            : underived;

          return complete;
        },
      })
    );
  }
}
