/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
/* eslint-disable spaced-comment */
/* eslint-disable no-bitwise */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { traits } from '../../react-app/src/constants/enums';
import { MESSAGE_TYPES } from './app/rn-messages';
import addMessageAlert from './message-alert';
import { isNativeMobilePortrait } from './native-app/app-utils';
import { getCookie } from './utils';

// This should probably go somewhere else (keeping camelCase for consistency's sake)
window.traits = traits;
// Globals for use in multiple js files.
window.dropzoneDictDefaultPhotoMessage =
  "<i class='fa-solid fa-camera fa-3x'></i><br><b>Drag photo here</b></b><br>OR<br><button class='btn btn-sm btn-secondary' type='button'>Select image</button>";
window.dropzoneDictDefaultImageMessage =
  "<i class='fa-solid fa-flag fa-3x'></i><br><b>Drag logo here</b></b><br>OR<br><button class='btn btn-sm btn-secondary' type='button'>Select logo</button>";
window.dropzoneDictDefaultAvatarMessage =
  "<i class='fa-solid fa-flag fa-3x'></i><br><b>Drag image here</b></b><br>OR<br><button class='btn btn-sm btn-secondary' type='button'>Select image</button>";

/**
 * CustomEvent polyfill for Internet Explorer
 */
// eslint-disable-next-line consistent-return
(() => {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

    return evt;
  }

  window.CustomEvent = CustomEvent;
})();

/**
 * Would be nice to use URLSearchParams, but it's not supported on IE
 */
const getQueryParameterByName = (name, url = window.location.href) => {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const setButtonLoading = (button) => {
  const $button = $(button);
  const loadingText = '<i class="fa-solid fa-circle-notch fa-spin"></i> <span>Loading...</span>';

  $button.attr('disabled', true);
  $button.html(loadingText);
};

$(function () {
  //
  // Bootstrap popovers
  //
  $(function () {
    $('[data-toggle="popover"]').popover();
  });

  // Cancel click event
  $('.cancel-action').click(function () {
    // alert('Cancel action occurs!');
    return false;
  });

  //
  // Advanced search.
  //

  // Necessary to set initial state? Otherwise first 'hide' causes a show.
  $('#advanced-search-bar').collapse({ toggle: false });
  // "Escape" anywhere in doc will close advanced search.
  $(document).on('keydown', function (e) {
    if (e.which === 27) {
      $('#advanced-search-bar').collapse('hide');
    }
  });
  // Clicking off adv search bar should hide.
  $(document).click(function () {
    $('#advanced-search-bar').collapse('hide');
  });
  // Advanced search button should toggle.
  $('.advanced-search-button').click(function (e) {
    $('#advanced-search-bar').collapse('toggle');
    window.scrollTo(0, 0);

    if (!$(e.target).hasClass('mobile-advanced-search')) {
      e.stopPropagation();
    }
  });
  // Prevent clicking on advanced search, or text search from toggling.
  $('#advanced-search-bar,.text-search').click(function (e) {
    e.stopPropagation();
  });

  // Cancel button should hide.
  $('#cancel-search-btn').on('click', () => {
    $('#advanced-search-bar').collapse('hide');
  });

  //
  //// Clear out any form key:value pairs with empty value.
  //XXX: This causes surprising behavior.
  //$('#search-form').submit(function() {
  //    if (!advSearchShown()) {
  //        // Reset the form so it doesn't affect the search.
  //        $("div.adv-search").find("select, input, checkbox").val("");
  //    }
  //});
  //
  $('#search-form').submit(function () {
    // check if visible rather than checking class 'show' b/c app won't use that class
    var advancedVisible = $('#advanced-search-bar').is(':visible');
    if (!advancedVisible) {
      //
      // Basic Search
      //
      // Reset the form so it doesn't affect the search.
      // In other words, the current results may have advanced search parameters
      // like min_genes. When they enter a new basic search, we don't want those to
      // affect the new search.
      $('div.adv-search').find('select, input, checkbox').val('');
      // If they're doing a basic search, fill in the category select which we
      // just blanked above with the default context.
      var defaultContext = $('body').data('category-context');
      $('#advanced-search-bar').find('#id_cat').val(defaultContext);
    } else {
      //
      // Advanced Search
      //
    }
  });

  //
  // Responsiveness for text search.
  //

  /**
   * Reposition .text-search when window width cross threshold.
   */
  var positionTextSearch = function (e) {
    var top = $('.navbar');
    var TEXT_SEARCH_BREAKPOINT = 768;
    //$('#search-input').val('width: ' + window.outerWidth + "," + $(window).width());
    if (window.innerWidth <= TEXT_SEARCH_BREAKPOINT) {
      top.addClass('text-collapsed');
    }
    // This is default -- for bigger screens.
    else {
      top.removeClass('text-collapsed');
    }
  };
  positionTextSearch();
  $(window).resize(positionTextSearch);

  // Handle logout with JS b/c we need to POST but our anchor is already in a form.
  $('.logout-button').click(function (event) {
    var logoutUrl = $(event.target).attr('data-target');
    var data = {
      content: 'xxx',
      // eslint-disable-next-line no-undef
      csrfmiddlewaretoken: window.CSRF_TOKEN,
    };
    // After logout, go to login page.
    $.post(logoutUrl, data, function () {
      window.location.replace($(event.target).attr('next'));
    });
  });

  $('#export-modal .modal-footer a').click(function () {
    $('#export-modal').modal('hide');
  });

  var activateSubmitSpinner = function (e) {
    // Only activate this form spinner if this is not app app on mobile b/c app app has it's own spinner
    if (!isNativeMobilePortrait()) {
      // Feels like I could almost just put loading-animation on the form, instead of
      // also having submit-spinner. But I think I have problems with the css screwing up
      // my modal
      //
      // This doesn't seem to work in Safari in terms of displaying the spinner
      // but it does block multiple submissions.
      $(this).append("<div class='loading-animation'></div>");
      $(this).find('.loading-animation').css('display', 'block');
      // See below, we have to turn this animation OFF if client-side blocks submission.
    }
  };
  $('form.submit-spinner').submit(activateSubmitSpinner);
  $('a.submit-spinner').click(activateSubmitSpinner);
  $('button.submit-spinner').click(activateSubmitSpinner);

  // Our alerts are dismissable but we still want to follow links.
  $('.main-alerts .alert a').on('click', function () {
    // We could intstead try changing the data-dismiss to a trigger
    // to jquery hide the alert, and see if URL got followed in that case.
    // Probably yes, if the issue is that data-dismiss stops event propagation.
    if ($(this).hasClass('same-window')) {
      window.location.replace($(this).attr('href'));
    } else {
      window.open($(this).attr('href'));
    }
  });

  // Used on index page.
  $('.state-toggle label').on('click', function () {
    window.location = $(this).attr('href');
  });

  /**
   * Create a marker on the static gmap
   */

  const toggleFollowStore = function (store_id, follow) {
    if (typeof follow === 'undefined') {
      follow = true;
    }
    // eslint-disable-next-line no-undef
    if (!IS_AUTHENTICATED) {
      const currentUrl = window.location.pathname;
      // eslint-disable-next-line no-undef
      window.location.href = `${window.URLS.LOGIN}?next=${currentUrl}`;
      return Promise.reject('User is not authenticated');
    }

    let followStoreEndpoint = '/api/v1/followed_stores/';

    const requestData = {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': window.CSRF_TOKEN,
      },
    };

    if (!follow) {
      requestData.method = 'DELETE';
      followStoreEndpoint += `${store_id}/`;
    } else {
      requestData.method = 'POST';
      requestData.body = JSON.stringify({
        id: store_id,
      });
    }

    return fetch(followStoreEndpoint, requestData).then(function (response) {
      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }
    });
  };

  const toggleFollowStoreEvt = function (evt, follow) {
    evt.preventDefault();

    if (typeof follow === 'undefined') {
      follow = true;
    }

    const $this = $(this);
    const storeId = $this.data('storeId');

    const clearFollowButton = function () {
      $this.button('reset');
    };

    $('.followFeature').each((i, b) => {
      setButtonLoading(b);
    });

    if (follow) {
      return toggleFollowStore(storeId)
        .then(function () {
          window.location.reload();
        })
        .catch(clearFollowButton);
    }

    return toggleFollowStore(storeId, false)
      .then(function () {
        window.location.reload();
      })
      .catch(clearFollowButton);
  };

  $('body').on('click', '.followStoreBtn', function (event) {
    toggleFollowStoreEvt.call(this, event);
  });

  $('body').on('click', '.unfollowStoreBtn', function (event) {
    toggleFollowStoreEvt.call(this, event, false);
  });

  const toggleBlockStore = function (store_id, block) {
    if (typeof block === 'undefined') {
      block = true;
    }
    // eslint-disable-next-line no-undef
    if (!IS_AUTHENTICATED) {
      const currentUrl = window.location.pathname;
      // eslint-disable-next-line no-undef
      window.location.href = `${window.URLS.LOGIN}?next=${currentUrl}`;

      return Promise.reject('User is not authenticated');
    }

    let blockStoreEndpoint = '/api/v1/blocked_stores/';

    const requestData = {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': window.CSRF_TOKEN,
      },
    };

    if (!block) {
      requestData.method = 'DELETE';
      blockStoreEndpoint += `${store_id}/`;
    } else {
      requestData.method = 'POST';
      requestData.body = JSON.stringify({
        id: store_id,
      });
    }

    return fetch(blockStoreEndpoint, requestData).then(function (response) {
      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }
    });
  };

  const toggleBlockStoreEvt = function (evt, block) {
    evt.preventDefault();

    if (typeof block === 'undefined') {
      block = true;
    }

    const $this = $(this);
    const storeId = $this.data('storeId');

    const clearBlockButton = function () {
      $this.button('reset');
    };

    $('.followFeature, .sendNewMessageBtn, .confirm-store-block, .cancel-store-block').each((i, b) => {
      setButtonLoading(b);
    });

    if (block) {
      return toggleBlockStore(storeId)
        .then(function () {
          hideConfirmBlockStorePopup();
          window.location.reload();
        })
        .catch(clearBlockButton);
    }
    return toggleBlockStore(storeId, false)
      .then(function () {
        window.location.reload();
      })
      .catch(clearBlockButton);
  };
  const hideConfirmBlockStorePopup = function() {
    $('.block-store-confirmation-dialog-wrap').hide()
  }
  const showConfirmBlockStorePopup = function() {
    $('.block-store-confirmation-dialog-wrap').show()
  }
  $('body').on('click', '.confirm-store-block', function (event) {
    toggleBlockStoreEvt.call(this, event, true);
  });

  $('body').on('click', '.cancel-store-block', function () {
    hideConfirmBlockStorePopup();
  });

  $('body').on('click', '.blockStoreBtn', function () {
    showConfirmBlockStorePopup();
  });

  $('body').on('click', '.unblockStoreBtn', function (event) {
    toggleBlockStoreEvt.call(this, event, false);
  });

  $('.sendNewMessageBtn').on('click', function (e) {
    window.loadMiniReactApp && window.loadMiniReactApp('direct-message-app');
  });

  // Anticipate SecurityErrors in some situations
  try {
    // Show Django messages stored in sessionStorage
    // eslint-disable-next-line no-undef
    const messages = JSON.parse(sessionStorage.getItem(DJANGO_MESSAGES_SS_KEY));
    if (messages) {
      messages.forEach((msg) => addMessageAlert(msg));

      // Remove them once they're shown
      // eslint-disable-next-line no-undef
      sessionStorage.removeItem(DJANGO_MESSAGES_SS_KEY);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
});

//
// Common Dropzone Code.
// see long discussion here about S3 support:
//   https://github.com/enyo/dropzone/issues/33
//
const dropzoneImageAccept = function (file, done) {
  const afterValidation = function (failMessage) {
    if (!failMessage) {
      getSignedRequest(file, done);
    } else {
      done(failMessage);
    }
  };
  validateImage(file, afterValidation);
};

// because this file is an ES module now, we need to do this
// until we do imports everywhere.
window.dropzoneImageAccept = dropzoneImageAccept;

function validateImage(file, done, validationConfig = {}) {
  var pixels = 0;
  // Apparently the file width/height aren't automatically set until thumbs
  // are created so we have to load it ourself.
  var reader = new FileReader();
  // eslint-disable-next-line no-shadow
  reader.onload = function (file) {
    var image = new Image();
    image.src = file.target.result;
    image.onload = function () {
      var errMessage = '';

      if ('ratio' in validationConfig) {
        var image_ratio = image.width / image.height;
        if (
          image_ratio > validationConfig.ratio * (1 + validationConfig.tolerance) ||
          image_ratio < validationConfig.ratio * (1 - validationConfig.tolerance)
        ) {
          errMessage += 'Photo does not meet required width-to-height ratio.';
        }
      }

      if ('minWidth' in validationConfig) {
        if (image.width < validationConfig.minWidth) {
          errMessage += `Photo does not meet required minimum width (${validationConfig.minWidth}px)`;
        }
      }

      if (errMessage.length !== 0) {
        done(errMessage);
      } else {
        done();
      }
    };
  };
  reader.readAsDataURL(file);
}

// because this file is an ES module now, we need to do this
// until we do imports everywhere.
window.validateImage = validateImage;

// Get a signed request and store the "signature" on the file object.
function getSignedRequest(file, done) {
  //console.log("Getting sign s3");
  $.ajax({
    url: '/api/v1/images/s3_presigned_post/animal_image_upload_presigned_post',
    dataType: 'json',
    data: { file_name: file.name, file_type: file.type },
    success(response) {
      //console.log("Got s3 sign in");
      file.s3Data = response.data;
      file.image_url = response.image_url;
      file.image_variants = response.image_variants;
      done();
    },
    error(response) {
      done('Could not get signed URL.');
    },
  });
}

// because this file is an ES module now, we need to do this
// until we do imports everywhere.
window.getSignedRequest = getSignedRequest;

// Copy the URL which is dynamic into options.
// https://github.com/enyo/dropzone/wiki/Set-URL-dynamically
const dropzoneProcessing = function (file) {
  //console.log("Processing, setting upload URL");
  this.options.url = file.s3Data.url; // upload URL
};

// because this file is an ES module now, we need to do this
// until we do imports everywhere.
window.dropzoneProcessing = dropzoneProcessing;

// Copy S3 signature into request.
const dropzoneSendingImage = function (file, xhr, formData) {
  //console.log("Sending image");
  // Copy parameters to sign the request from file into form
  $.each(file.s3Data.fields, function (k, v) {
    formData.append(k, v);
  });
  // For local, so we know where to save this to; but can't add
  // anything extra for s3 or it fails.
  if (file.s3Data.fields.key == null) {
    formData.append('file_path', file.image_url);
  }
};

// because this file is an ES module now, we need to do this
// until we do imports everywhere.
window.dropzoneSendingImage = dropzoneSendingImage;

// Notify server to update image URL on model.
const dropzoneSuccess = function (
  file,
  response,
  success_url,
  image_type,
  successCallback,
  errorCallback
) {
  //console.log("Updating image on server");
  // Grab from the file in the event of local.
  var file_path = file.s3Data.fields.key || file.image_url;
  $.ajax({
    url: success_url,
    data: {
      file_path,
      image_type,
    },
    success() {
      if (successCallback) {
        successCallback();
      }
    },
    error(e) {
      if (errorCallback) {
        errorCallback();
      }
      if (e.status == 400) {
        alert(e.responseText);
      } else {
        alert("Server couldn't update image.");
      }
    },
  });
};

// because this file is an ES module now, we need to do this
// until we do imports everywhere.
window.dropzoneSuccess = dropzoneSuccess;

(() => {
  $(function () {
    $('.notifications div.alert').on('click', function (e) {
      const $notification = $(e.currentTarget);
      if (!$(e.target).is('a')) {
        $.post({
          url: window.URLS.NOTIFICATIONS,
          data: {
            notification_id: $notification.data('id'),
            csrfmiddlewaretoken: getCookie('csrftoken'),
          },
          success(res) {
            $notification.alert('close');
          },
        });
      }
    });
  });
})();

if (window.ReactNativeWebView) {
  if (window.USERNAME) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: MESSAGE_TYPES.USER_IS_LOGGED_IN,
        payload: {
          username: window.USERNAME,
        },
      })
    );
  } else {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: MESSAGE_TYPES.USER_IS_LOGGED_OUT,
      })
    );
  }
}
