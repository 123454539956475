/**
 * IMPORTANT!
 * Do not use any fancy Javascript language functionality here as this file is directly imported by the browser
 * and needs to work in old versions of Safari (11-13) where many modern features just aren't supported.
 * When in doubt, check caniuse.com to see if that language feature is supported.
 */

/**
 * This function formats the data so that legacy parts of the codebase can digest it.
 */
export const organizeTraitsByCategory = (traits) => {
  const replaceHet = (value) => value.replace(/100% het/i, 'Het');
  const withoutPos = (value) => value.match(/^pos |%/i) === null;
  const processTraits = ([key, value]) => {
    if (!!value.traits_data) {
      const sansHet = value.traits_with_aliases_data.map(replaceHet);

      return [
        key,
        {
          ...value,
          traits_with_aliases_data: sansHet,
          traits_aliases_pos: sansHet,
          traits_aliases: sansHet.filter(withoutPos),
        },
      ];
    } else {
      return [key, value];
    }
  };

  return Object.entries(traits)
    .map(processTraits)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};
